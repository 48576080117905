import React , {useEffect, useState} from "react";
import { Progress } from 'antd';
import { useRecoilState } from 'recoil';
import { totalSypply } from './../../atom';
import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import { graphAPIGov } from "../../open2web3";
import TopVoters from "./TopVoters";
import VotersList from './VotersList';
function AbstainBox(props) {

    const client = new ApolloClient({
        uri: graphAPIGov,
        cache: new InMemoryCache(),
      });

    const [contractTotalSypply, setContractTotalSypply] = useRecoilState(totalSypply);
    const [topVoters , setTopVoters] = useState([]);
    const [openVotersList , setOpenVotersList] = useState(false);
  const getTopVoters = async () => {
      client
        .query({
          query: gql`{
                votes( where : {proposalId: "${props.proposalId}" support:"2" } first:100 ){
                  id
                  proposalId
                  voter
                  support
                  weight
                  date
                }
              }`,
        })
        .then((result) => setTopVoters(result.data.votes));
    };

    useEffect(()=>{
        getTopVoters()
    },[])

    const showModal = () => {
      setOpenVotersList(true);
    };
  
    const handleOk = () => {
      setOpenVotersList(false);
    };
  
    const handleCancel = () => {
      setOpenVotersList(false);
    };

    return ( 
        <>
      <div className="InfoToken">
        <div className="proposalText"><h6>Abstain</h6><div>{props.votes}
        </div></div> 

      
    <Progress percent={((props.votes/ contractTotalSypply) *100 ).toFixed(2)} status="exception"/>
    
    <div className="tokenInfoD adV"> <div>Addresses  {props.count && props.count}</div>
    <div>votes</div>
    </div>

    {
         topVoters.map((voter) =>
         <TopVoters key={voter.voter} address={voter.voter} weight={voter.weight} voteState={"2"} voteTitle={"ABSTAIN"} proposalId={props.proposalId}/>
       )
      }
      {props.count>0 && <a onClick={showModal} className="viewS">View List</a>}
      <VotersList open={openVotersList} handleOk={handleOk} handleCancel={handleCancel} voteState={"2"} proposalId={props.proposalId} voteTitle={"ABSTAIN"}/>
    
    </div>
    </>
     );
}

export default AbstainBox;