import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes  , Navigate} from "react-router-dom";
import Tokens from "./components/Tokens";
import Vote from "./components/Vote";
import Finance from "./components/Finance";
import { Layout, Menu, Breadcrumb, Button } from "antd";
 
import LeftNav from "./components/LeftNav";
import Login from "./components/connection/Login";
import Logout from "./components/connection/Logout";
import Connection from './components/connection/Connection';
import { useRecoilState } from 'recoil';
import { connected } from "./atom";
import Home from './components/Home';
import ProtectedRoute from "./components/connection/ProtectedRoute ";
import NavBar from "./components/NavBar";
import ProposalDetails from './components/ProposalDetails';
import FooterComponent from './components/FooterComponent';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import ChatBot from "./components/ChatBot/ChatBot";

function App() {
  const { Header, Content, Footer, Sider } = Layout;
  const [connectedAddress , setConnectedAddress] = useRecoilState(connected);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      {/* {connectedAddress!="" ? <LeftNav /> : null} */}

      <Layout className="site-layout">
        {/* <Header
          className="site-layout-background"
          style={{
            padding: 0,
          }}
        >
           <Connection/>
          </Header> */}
          <NavBar/>
        <Content
          style={{
            margin: "24px 25px",
          }}
        >
          <div
            className="site-layout-background"
            style={{
          
              minHeight: 360,
            }}
          >
            <Routes>
              <Route path="/tokens" element={<Tokens />} />
              <Route path="/vote" element={<Vote />} />
              <Route path="/finance" element={<Finance />} />
              <Route path="/home" element={<Home />} />
              <Route path="/proposal/:proposalId" element={<ProposalDetails />} />
              {/* <Route
        path="*"
        element={<Navigate to={connectedAddress!="" ? "/tokens": "/home"} replace />}
    /> */}
              <Route
        path="*"
        element={<Navigate to={"/tokens"} replace />}
    />
              {/* <Route exact path="/">
              <Redirect to="/tokens" />
    
</Route> */}
            </Routes>
          </div>
          <ChatBot/>
        </Content>
       
        
        <FooterComponent/>
      </Layout>
    </Layout>
  );
}

export default App;
