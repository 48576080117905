import HistoryBoxStatus from "./HistoryBoxStatus";

function HistoryBox() {
    return ( <div className="InfoToken">
 
          <h6 style={{textAlign:'center'}}>Proposal History</h6>
          <HistoryBoxStatus label="Created" date="October 10th, 2022 – 2:26am" icon="/check.svg" />
         
      

          
    </div> );
}

export default HistoryBox;