
import { Alert, Button, notification } from "antd";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useRecoilState, useResetRecoilState } from 'recoil';
import { balance, connected, selectedNetwork }  from '../../atom'
import { chainId, delegate, getBalance, getVotes, networks } from "../../open2web3";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import UpdateValuesComponent from './../UpdateValuesComponent';
import { ControlPointDuplicateOutlined } from "@material-ui/icons";

const Login = () => {
  const [connectedAddress , setConnectedAddress] = useRecoilState(connected);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNetworkState , setSelectedNetworkState] = useRecoilState(selectedNetwork)
  const [balanceConnectedUser, setBalanceConnectedUser] =
  useRecoilState(balance);
  const location = useLocation()

  const openNotificationAccountChanged = () => {
    notification.open({
      message: 'Account changed',
      description:
        'Please make sure of your current metamask account.',
      onClick: () => {
        console.log('Notification Clicked!');
      },
    });
  };

  useEffect(()=>{
    if(selectedNetwork!=chainId && selectedNetwork!=""){
      chainChangedHandler()
    }
    console.log('connected add ',connectedAddress)
    delegateToSelf(connectedAddress)
    // delegate(connectedAddress)
  },[connectedAddress])


  useEffect(()=>{

    const address = localStorage.getItem('lastConnectedAddress');
    if(address!=null && address.length>0){
      connectWalletHandler();
    }
    console.log('loc ',location)
    

  },[]);

  // const connect = () => {
  //   window.ethereum.request({method:'eth_requestAccounts'})
  //   .then(res=>{
  //           // Return the address of the wallet
  //           console.log('res ',res);
  //           setConnectedAddress(res);
            
 

  //           console.log(res) 
  //   })
  // };


  const connectWalletHandler = () => {
    // console.log('nidhal trying to connect')
    
		if (window.ethereum && window.ethereum.isMetaMask) {
			// console.log('MetaMask Here!');
      console.log(" ----account-");
			window.ethereum.request({ method: 'eth_requestAccounts'})
			.then(result => {
        // console.log('hhhhhhhhh' ,result);
				accountChangedHandler(result[0]);
        setSelectedNetworkState(window.ethereum.chainId)
			//	setConnButtonText('Wallet Connected');
				//getAccountBalance(result[0]);
        if(location.pathname!='/vote' && location.pathname!='/finance'){
          // navigate(location.pathname);
        }
			})
			.catch(error => {
				console.log(error.message);
			
			});

		} else {
			// console.log('Need to install MetaMask');
			// console.log('Please install MetaMask browser extension to interact');
      info();
		}
	}

  const delegateToSelf =async (newAccount)=>{

    const votes =await getVotes(newAccount);
    const balance =await  getBalance(newAccount);

    console.log('aacc : '+balance+'  votes : ',votes)
    if (votes!=balance){
      await delegate(newAccount);
      }

  }

	// update account, will cause component re-render
	const accountChangedHandler =async (newAccount) => {
    // console.log('ETH ',window.ethereum.chainId)
    // debugger;
    console.log("new acc ",newAccount);
    let  i=0;
    i++;
    // console.log('i ',i);
		

    localStorage.setItem('connected',true);
    localStorage.setItem('lastConnectedAddress',newAccount);
    
  
      if (newAccount!=connectedAddress){
        setConnectedAddress(newAccount);
        
      }
    // chainChangedHandler(window.ethereum.chainId);
    // debugger;
  

    
     
    
	//  console.log('here', newAccount);
	}

  const chainChangedHandler = async (selectedNetwork) => {
		// reload the page to avoid any errors with chain change mid use of application
   
		// window.location.reload();
    // console.log("network ", window.web3.currentProvider );
    // console.log("network selected ", selectedNetwork);

   
      
       await changeNetwork(chainId ==='0xa869' ? 'Fuji':'avalanche');
      
       setSelectedNetworkState(window.ethereum.chainId)
    
	}

  // useEffect(()=>{
  //   console.log("Network changed ", selectedNetwork)
  //   if(selectedNetwork == chainId){
      
  //   }
  // },[selectedNetwork])
try{

 
  // window.ethereum.on('disconnect',disconnect);
  // window.ethereum.on('accountsChanged', connectWalletHandler);
  window.ethereum.on('accountsChanged', connectWalletHandler);
  window.ethereum.on('chainChanged', chainChangedHandler);
 
}catch(error){

}


const handleOk = () => {
  setIsModalVisible(true);
};

const handleCancel = () => {
  setIsModalVisible(false);
};


  const changeNetwork = async (networkName) => {
     
    try {
      console.log("network name ", networkName);
      if (!window.ethereum) throw new Error('No crypto wallet found')
       await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            ...networks[networkName],
          },
        ],
      })
      
      
    } catch (err) {
      // setError(err.message)
    }
  }

  const info = () => {
    Modal.info({
      title: 'Metamask !',
      content: (
        <div>
          <p>Please install metamask on your browser .</p>
         
        </div>
      ),
      onOk() {window.open('https://metamask.io/', '_blank');},
    });
  };

  const disconnect = () => {
    console.log("user dis")
    setConnectedAddress("");
    setBalanceConnectedUser('')
    localStorage.setItem('connected',false);
    localStorage.setItem('lastConnectedAddress',[]);
    // navigate('/home');
     
  };
 
  

 return (
   <React.Fragment>
    <UpdateValuesComponent/>
    {connectedAddress !='' ? 
    <Button type="primary" size={20} onClick={disconnect}>
    <img src="/metamask.png" width="22" style={{marginRight:10}} />  Logout
  </Button>
     : 
    <Button id='login-btn' type="primary" size={20} onClick={connectWalletHandler}>
    <img src="/metamask.png" width="22" style={{marginRight:10}} />  Login
  </Button>}
    

 

  <Modal title="Metamask not found" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>Please install metamask !</p>
     
      </Modal>
 
  </React.Fragment>
 );


 
}

export default Login;