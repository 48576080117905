import React from "react";
import { Layout, Menu, Breadcrumb, Button } from "antd";
import { facebookLink, instagramLink, meduimLink, telegramLink, discordLink } from './../open2web3';
function FooterComponent() {
    const {Footer } = Layout;
    return ( 
        <Footer>
        <div>
        © Copyright O2B DAO 2022 All Rights Reserved 
        </div>
        <div>
         <a href={facebookLink} target="_blank"><img src="/fb.svg" /></a>
         <a href={instagramLink} target="_blank"><img src="/inst.svg" /></a>
         <a href={meduimLink} target="_blank"><img src="/meduim.svg" /></a>
         <a href={telegramLink} target="_blank"><img src="/telegram.svg" /></a>
         <a href={discordLink} target="_blank"><img src="/discord.svg" /></a>
        </div>
      </Footer>
     );
}

export default FooterComponent;