import React from "react";
import { blockExplorer } from './../open2web3';
function InfoToken(props) {
  return (
    <>
      <div className="tokenInfoD">
        <div>
          <h6>Total supply</h6>
        </div>
        <div>{props.contractTotalSypply}</div>
      </div>

      <div className="tokenInfoD">
        <div>
          <h6>Transferable</h6>
        </div>
        <div>{props.transferable}</div>
        
      </div>

      <div className="tokenInfoD">
        <div>
          <h6>Token</h6>
        </div>
        <div><a href={blockExplorer+'/address/'+props.contractAddress} target="_blank" style={{color:'#fff',textDecoration:'none'}}>{props.tokenNameAndSymbol}</a></div>
      </div>
    </>
  );
}

export default InfoToken;
