
import React from 'react';
import { blockExplorer } from './../open2web3';

function TreasuryTokenInfoComponent(props) {
    return (  
    <>
     {/* <div className="tokenInfoD">
        <div>
          <h4>TOKEN INFO</h4>
        </div>
         
      </div> */}

      <div className="tokenInfoD">
        <div>
          <h6>Token</h6>
        </div>
        <div><a href={blockExplorer+'/address/'+props.contractAddress} style={{color:'#fff',textDecoration:'none'}} target="_blank">{props.treasuryTokenInfo}</a></div>
      </div>
    
      </>
    );
}

export default TreasuryTokenInfoComponent;