import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
  ConversationHeader,
} from "@chatscope/chat-ui-kit-react";
import { WechatOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import { useState } from "react";

const API_KEY = "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";
// "Explain things like you would to a 10 year old learning how to code."
const systemMessage = {
  //  Explain things like you're talking to a software professional with 5 years of experience.
  role: "system",
  content:`Explain things as you are an assistant in a decentralized autonomous organization application;
    Talk only about decentralized autonomous organizations;
    Explain connection-related issues with the metamask;
    This app has three main pages: one called token to see the governance token balance; two called vote to create and vote on proposals; and you can click on a proposal to see its details. 3 is finance to see all related fund transfers due to a succssfuel vote;
    Try to stay in the decentralized autonomous organization application context, and any time you find the user wants to change context, do not answer and ask them if they need help in Dao;
    Do not provide any programming code even if related decentralized autonomous organization application context and ask them if they need help in Dao;
  
  `,
};

export default function ChatBot({ props }) {
  const [showBox, setShowBox] = useState(false);
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm Your assistant! Ask me anything!",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map((messageObject) => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return { role: role, content: messageObject.message };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
      "temperature" : 0.2
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => {
        return data.json();
      })
      .then((data) => {
        console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <div className="desChat">
      <div>
        {showBox && (
          <MainContainer>
            <ChatContainer>
              <ConversationHeader
                onClick={() => {
                  setShowBox(false);
                }}
              >
                <Avatar src={<WechatOutlined />} name="GPT" />
                <ConversationHeader.Content userName="GPT" info="Active" />
              </ConversationHeader>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator content="ChatGPT is typing" />
                  ) : null
                }
              >
                {messages.map((message, i) => {
                  console.log(message);
                  return <Message key={i} model={message} />;
                })}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        )}
      </div>
      <div>
        {" "}
        {!showBox && (
          <WechatOutlined
            style={{ position:"absolute" , fontSize: "40px", color: "#08c" , right:'50px' , bottom:"90px" }}
            onClick={() => {
              setShowBox(true);
            }}
          />
        )}
      </div>
    </div>
  );
}
